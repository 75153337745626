<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>我的班级</el-breadcrumb-item>
      <el-breadcrumb-item>{{tablist[activeIndex]}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs v-model="activeIndex" v-if="!errorflag"
             type="card"
             @tab-click="handleClick">
      <el-tab-pane v-for="(pitem,pindex) in tablist"
                   :key="pindex"
                   :label="pitem"
                   :name="''+pindex">
        <el-table :data="classesinfo"
                  style="width: 100%"
                  :row-class-name="tableRowClassName">
          <el-table-column prop="Campus"
                           label="所在校区"
                           align="center"
                           width="200">
          </el-table-column>
          <el-table-column label="课程学期"
                           width="150">
            <template slot-scope="scope">
              <div>
                {{scope.row.Year}}{{scope.row.Season}}{{scope.row.SubSeason}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="课程名字"
                           width="300">
            <template slot-scope="scope">
              <div class="tableline">
                <span>{{scope.row.Subject}}</span>
                <span>{{scope.row.GradeStart}}</span>
                <span>{{scope.row.ClassType}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="上课时间">
            <template slot-scope="scope">
              <div class="tableline">
                <span v-show="scope.row.Weekly!=null">{{scope.row.Weekly}}</span>
                <span>{{scope.row.StartTime +' - '+scope.row.EndTime}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           width="300">
            <template slot-scope="scope">
              <div class="handle">
                <p @click="godetail('/class/classdetail', scope.row.DBType,scope.row.OprId)">课程详情</p>
                <p v-show="scope.row.is_score=='1'"
                   class="flex"
                   @click="gonav('/class/achievement', scope.row.DBType,scope.row.OprId)">我的成绩</p>
                <p class="flex"
                   @click="gonav('/class/coursemater', scope.row.DBType,scope.row.OprId)">课程资料<img src="../../assets/img/download_icon.png"
                       alt=""></p>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <div class="flex pagination">
        <p class="pn disabd"
          v-if="page==1">首页</p>
        <p v-else
          class="pn"
          @click="handleCurrentChange(1)">首页</p>
          <el-pagination @current-change="handleCurrentChange"
                        :current-page.sync="page"
                        :page-size="pagesize"
                        layout="prev, pager, next"
                        prev-text="<< 上一页"
                        next-text=">> 下一页"
                        :total="totalpage*pagesize">
          </el-pagination>
          <p class="pn disabd"
            v-if="page==totalpage">尾页</p>
          <p v-else
            class="pn"
            @click="handleCurrentChange(totalpage)">尾页</p>
      </div>
    </el-tabs>
    <div v-else class="errortips">{{errortips}}</div>
  </div>
</template>

<script>
import { classes_info } from '@/api/classesinfo'
export default {
  name: 'class',
  data () {
    return {
      activeIndex: '0',
      tablist: ['正在上课的班级', '历史的班级'],
      classesinfo: [],
      page: 1,
      pagesize: 5,
      totalpage: 1,
      errorflag:false,
      errortips:''   
    };
  },
  created () {
    sessionStorage.removeItem('stu_class_query')
    let classindex= localStorage.getItem('stu_classindex')? JSON.parse(localStorage.getItem('stu_classindex')):null 
    if(classindex){
      this.activeIndex=classindex.activeIndex
      this.page=classindex.page
    }
    this.getInit()
  },
  beforeRouteEnter (to, from, next) {
    //||(to.path=='/class'&&from.path=='/')
    if(from.path=='/class/classdetail'||from.path=='/class/achievement'||from.path=='/class/coursemater'){
     
    }else{
      localStorage.removeItem('stu_classindex')
    }
    next()
  },
  methods: {
    async getInit () {
      let prames = {
        userid: this.$store.state.user.stu_user_info[0].id,
        type: this.activeIndex == 0 ? 1 : 2,
        page: this.page,
        pagesize: this.pagesize
      }
      let res = await classes_info(prames)
      if (res.flag == '1') {
        this.classesinfo = res.datainfo.classesinfo;
        this.totalpage = res.datainfo.totalpage;
        this.page = Number(res.datainfo.currentPage);
      } else if (res.flag == '0') {
        this.classesinfo = [];
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      }else if (res.flag == '2') {
        this.errorflag=true;
        this.errortips=res.msg        
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    },
    //奇偶数背景色
    tableRowClassName ({ rowIndex }) {
      if (rowIndex % 2 == 0) {
        return 'even';
      } else if (rowIndex % 2 == 1) {
        return 'odd';
      }
      return '';
    },
    handleClick (tab) {
      this.activeIndex = tab.index;
      this.page=1;
      this.getInit()
    },
    godetail (path, DBType, OprId) {
      this.$router.push({
        path: path,
      })
      var query={
        DBType: DBType,
        OprId: OprId,
        activeIndex:this.activeIndex
      }
      sessionStorage.setItem('stu_class_query',JSON.stringify(query) )
      localStorage.setItem('stu_classindex',JSON.stringify({activeIndex:this.activeIndex,page:this.page}))
    },
    gonav (path, DBType, OprId) {
      this.$router.push({
        path: path,
      })
      var query={
        DBType: DBType,
        OprId: OprId,
      }
      sessionStorage.setItem('stu_class_query',JSON.stringify(query) )
      localStorage.setItem('stu_classindex',JSON.stringify({activeIndex:this.activeIndex,page:this.page}))
    },
    handleCurrentChange (val) {
      this.page = val;
      this.getInit()
      console.log(this.page);
    }
  },

}
</script>
<style scoped lang="scss">
@import "class.scss";
</style>